import dayjs from '@/dayjs';

let InternalFilters = {};

InternalFilters.install = function (Vue) {
  Vue.filter('formatDate', function(value) {
    if (value) {
      return dayjs(String(value)).local().format('DD/MM/YYYY');
    }
  });
  Vue.filter('formatTime', function(value) {
    if (value) {
      return dayjs(String(value), ['HH:mm', 'HH:mm:ss']).format('HH:mm');
    }
  });
  Vue.filter('formatDatetime', function(value) {
    if (value) {
      return dayjs(String(value)).local().format('DD/MM/YYYY hh:mma');
    }
  });
  Vue.filter('fromNow', function(value) {
    if (value) {
      return dayjs(String(value)).local().fromNow(false);
    }
  });
  Vue.filter('fromNowDays', function(value) {
    if (value) {
      return Math.abs(dayjs(String(value)).local().diff(dayjs(), 'days')) + 'd';
    }
  });
  Vue.filter('commaList', function(value) {
    if (value) {
      return value.join(', ');
    }
  });
  Vue.filter('dashEmpty', function(value) {
    if (value) return value;
    else return '-';
  });
};

export default InternalFilters;
