const DefaultTitleAppend = ' - ASG';

export default {

  currentTitle: null,
  unreadNotif: 0,

  iconLinkId: 'icon-link',
  
  
  install(Vue, options) {
    if (!options.router) {
      throw 'router must be passed in options';
    }
    this.Vue = Vue;

    const router = options.router;
    const wsnotify = options.wsnotify;

    if (options.titleAppend) {
      this.titleAppend = options.titleAppend;
    }

    router.beforeEach((to, from, next) => {
      // This goes through the matched routes from last to first, finding the closest route with a title.
      // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
      const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
    
      // If a route with a title was found, set the document (page) title to that value.
      if (nearestWithTitle) {
        this.currentTitle = nearestWithTitle.meta.title;
        this.updateTitle();
      }
    
      next();
    });

    const setTitle = title => {
      this.currentTitle = title;
      this.updateTitle();
    };

    if (wsnotify) {
      wsnotify.on('notify', () => { this.unreadNotif++; this.updateTitle(); this.updateIcon(); });
      wsnotify.on('unread_count', count => { this.unreadNotif = count; this.updateTitle(); this.updateIcon(); });
    }

    Vue.$setTitle = setTitle;
    Vue.prototype.$setTitle = setTitle;
    this.Vue = Vue;
  },

  updateTitle() {
    let prepend = '';
    if (this.unreadNotif > 0) {
      prepend = `(${this.unreadNotif}) `;
    }
    document.title = prepend + this.currentTitle + this.titleAppend;
  },

  updateIcon() {
    const iconLink = document.getElementById(this.iconLinkId);
    if (this.unreadNotif > 0) 
    {
      iconLink.href = this.alertIcon;
    }
    else 
    {
      iconLink.href = this.baseIcon;
    }
  },

  get titleAppend() {
    return ` - ${this.Vue.$resources.companyName}` || DefaultTitleAppend;
  },

  get baseIcon() {
    return this.Vue.$resources.logoIconImg;
  },

  get alertIcon() {
    return this.Vue.$resources.logoIconAlertImg;
  },
};
