import RingLoader from './RingLoader';

const install = (Vue, options = {}) => {
  const name = 'spinner';
  Vue.component(options.prefix ? options.prefix + name : name, RingLoader);
};

// auto install
if (typeof window !== 'undefined' && window.Vue) {
  install(window.Vue);
}

export default install;
