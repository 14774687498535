var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "lds-ring",
      style: { width: _vm.size + "px", height: _vm.size + "px" }
    },
    [
      _c("div", { style: [_vm.spinnerStyle, _vm.animDiv1] }),
      _vm._v(" "),
      _c("div", { style: [_vm.spinnerStyle, _vm.animDiv2] }),
      _vm._v(" "),
      _c("div", { style: [_vm.spinnerStyle, _vm.animDiv3] }),
      _vm._v(" "),
      _c("div", { style: [_vm.spinnerStyle] })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }