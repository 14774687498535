import NProgress from 'nprogress';

const ProgressBar = {
  router: null,
  /**
   * Install the Progres plugin.
   *
   * Creates a axios http interceptor to add progress bar
   *
   * @param {Object} Vue The global Vue.
   * @param {Object} options Any options we want to have in our plugin.
   * @return {void}
   */
  /* eslint-disable no-shadow, no-unused-vars */
  install(Vue, options) {
    if (options.router == null) {
      throw 'Router must be defined';
    }
    this.router = options.router;

    this.router.beforeResolve((to, from, next) => {
      // If this isn't an initial page load.
      // and not preventing progress bar with meta flag noProgressOnSelfNav
      if (to.name && !(to?.meta?.noProgressOnSelfNav && to.name === from.name)) {
        NProgress.start();
      }
      next();
    });
    this.router.afterEach((to, from) => {
      NProgress.done();
    });
  },
};

export default ProgressBar;
